import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent implements OnInit {
  @Input() public successButtonLabelKey: string;
  @Input() public cancelButtonLabelKey: string;
  @Input() public titleKey: string;
  @Input() public textKey: string;

  public canCloseOnBackdropClick: boolean;
  public isCancelButton: boolean;

  constructor(private activeModal: NgbActiveModal) {
    this.successButtonLabelKey = '';
    this.cancelButtonLabelKey = '';
    this.titleKey = '';
    this.textKey = '';
    this.canCloseOnBackdropClick = true;
    this.isCancelButton = true;
  }

  public ngOnInit(): void {
    this.initIsCancelButtonState();
  }

  public buttonClick(action: boolean): void {
    this.activeModal.close(action);
  }

  private initIsCancelButtonState(): void {
    this.isCancelButton = this.cancelButtonLabelKey !== '';
  }
}
