import { HttpParams } from '@angular/common/http';

import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';

export class QueryParamsUtil {
  public static mapFiltersToHttpParams<FILTERS extends object>(filters: FILTERS): HttpParams {
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });

    Object.entries(filters).forEach(([key, value]: [string, string]): void => {
      if (Array.isArray(value)) {
        value.forEach((value: string) => {
          params = params.append(`${key}[]`, value);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return params;
  }
}
