import { Injectable } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';

import { PopupModalComponent } from '../../components/popup-modal/popup-modal.component';
import { ModalSizeEnum } from '../../enums/modal-size/modal-size.enum';
import { ModalSizeTypeModel } from '../../models/modal-size-type/modal-size-type.model';
import { BackdropModel } from '../../models/backdrop/backdrop.model';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  public openPopupModal(
    titleKey: string,
    textKey: string,
    successButtonLabelKey: string,
    cancelButtonLabelKey?: string
  ): Observable<boolean> {
    const modal: NgbModalRef = this.openModal(PopupModalComponent, ModalSizeEnum.small, false);
    const instance: PopupModalComponent = modal.componentInstance as PopupModalComponent;

    instance.titleKey = titleKey;
    instance.textKey = textKey;
    instance.successButtonLabelKey = successButtonLabelKey;

    if (cancelButtonLabelKey) {
      instance.cancelButtonLabelKey = cancelButtonLabelKey;
    }

    return from(modal.result) as Observable<boolean>;
  }

  public openModal(component: unknown, size: ModalSizeTypeModel = ModalSizeEnum.small, canCloseOnBackdrop: boolean = false): NgbModalRef {
    return this.prepareModal(component, size, canCloseOnBackdrop);
  }

  private prepareModal(component: unknown, size: ModalSizeTypeModel, canCloseOnBackdrop: boolean): NgbModalRef {
    const backdrop: BackdropModel = (canCloseOnBackdrop as true) || 'static';

    return this.ngbModal.open(component, { animation: false, centered: true, backdrop: backdrop, windowClass: `modal ${size}` });
  }
}
