import { ListDto } from '../../dtos/list/list.dto';
import { ListPaginationCreator } from '../list-pagination/list-pagination.creator';
import { ListModel } from '../../models/list/list.model';

export class ListCreator {
  public static create<DTO, MODEL>(dto: ListDto<DTO>, items: Array<MODEL>): ListModel<MODEL> {
    return {
      items: items,
      pagination: ListPaginationCreator.create(dto),
    };
  }
}
