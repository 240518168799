import { ListPaginationModel } from '../../models/list-pagination/list-pagination.model';
import { ListDto } from '../../dtos/list/list.dto';

export class ListPaginationCreator {
  public static create(dto: ListDto<unknown>): ListPaginationModel {
    return {
      page: dto.page,
      itemsPerPage: dto.per_page,
      itemsCount: dto.items.length,
      itemsTotalCount: dto.total_count,
    };
  }
}
