<app-modal>
  <span modal-title>{{ titleKey | translate }}</span>

  <div modal-content [innerHTML]="textKey | translate"></div>

  <ng-container modal-footer>
    <button class="button black-outline" *ngIf="isCancelButton" (click)="buttonClick(false)">
      {{ cancelButtonLabelKey | translate }}
    </button>

    <button class="button red" (click)="buttonClick(true)">
      {{ successButtonLabelKey | translate }}
    </button>
  </ng-container>
</app-modal>
